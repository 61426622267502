import React from "react"

import SEO from "../components/seo"
import rond_color from "../assets/rond_color.svg"

import Navigation from "../components/Navigation"


const politiquededonnees = () => (
    <div
        className="containerManifesto"
      >
        <div className=" container containerHome">
        <Navigation/>
        {/*<div className="rondTache row">
          <img src={rond} className="col-3 rond"/>
          <img src={tache} className="col-9 tache"/>
        </div>*/}
    <div>
    <SEO title="Plaiz - Politique de Données - Plaiz, le réseau social de la mode"/>
    <div>
    <h1 className="p1">Politique de Données</h1>

    <h4
      style={styles.backToHomeLink}
    >
    <p className="textChangeGameLeft"><br/>
          <br/>
          <br/>
          <b>Politique de gestion des données personnelles et de protection de la vie privée de PLAIZ</b><br/>
          <br/>
          <br/>
          Dans le cadre de notre activité de création de contenu digital, des applications associées, et des services annexes, nous sommes amenés à collecter certaines données à votre sujet. <br/>
          <br/>
          Conformément au règlement européen 2016/679, dit « règlement général sur la protection des données », la présente politique vous informe sur : <br/>
          <br/>
          - les principes encadrant la collecte de ces données ; <br/>
          <br/>
          - les conditions de la collecte de ces données ; <br/>
          <br/>
          - la finalité de la collecte de ces données ; <br/>
          <br/>
          - le traitement de ces données ; <br/>
          <br/>
          - la protection de ces données ; <br/>
          <br/>
          - vos droits de consultation et de suppression de ces données. <br/>
          <br/>
          Date de dernière mise à jour: 5 février 2019. <br/>
          <br/>
          <br/>
          ​
          <b>Définitions</b><br/>
          <br/>
          Afin que ce document soit parfaitement clair, commençons par définir quelques termes. <br/>
          <br/>
          « Plaiz »/« nous » Désigne la société Plaiz , SAS au capital de 100 €, immatriculée au RCS de Versailles sous le numéro 847 726 874. <br/>
          <br/>
          « Vous » Désigne la personne physique qui utilise nos sites et/ou nos services et/ou notre application, qu’importe le dispositif technique, pour son propre usage ou pour un tiers. <br/>
          <br/>
          « Partenaires » Désigne les personnes physiques et morales, les autorités et les institutions, les services et les produits, les applications et les sites web, qui ne sont pas directement contrôlés ou gérés par Plaiz, mais possèdent une relation contractuelle avec Plaiz. <br/>
          <br/>
          « Politique » Désigne le présent document, intitulé « Politique de gestion des données personnelles et de protection de la vie privée ». <br/>
          <br/>
          « Nos sites » Désigne les sites de contenu édités par Plaiz: Plaiz.io. Par extension : les notifications web, les flux RSS, et l’API issus de ces sites. <br/>
          <br/>
          « Nos services » Désigne les sites annexes édités par Plaiz. <br/>
          <br/>
          « Nos applications » Désigne l’app Plaiz. Par extension : les widgets et autres extensions issus de ces applications, ainsi que le module watchOS intégré à l’application Plaiz pour iOS. <br/>
          <br/>
          « Nos sondages » Désigne le module de sondage proposé sur l’Application Plaiz. <br/>
          <br/>
          « Données personnelles » Désigne toute donnée identifiant directement ou indirectement une personne physique, « tel qu’un nom, un numéro d’identification, des données de localisation, un identifiant en ligne, ou à un ou plusieurs éléments spécifiques propres à son identité physique, physiologique, génétique, psychique, économique, culturelle ou sociale » selon le règlement européen 2016/679. Votre nom, votre pseudonyme, votre adresse de courrier électronique ou votre adresse IP sont des données personnelles. <br/>
          <br/>
          « Traitement des données » Désigne « toute opération ou tout ensemble d’opérations effectuées ou non à l’aide de procédés automatisés et appliquées à des données ou des ensembles de données à caractère personnel, telles que la collecte, l’enregistrement, l’organisation, la structuration, la conservation, l’adaptation ou la modification, l’extraction, la consultation, l’utilisation, la communication par transmission, la diffusion ou toute autre forme de mise à disposition, le rapprochement ou l’interconnexion, la limitation, l’effacement ou la destruction ». <br/>
          <br/>
          <br/>​
          <br/>
          <br/>​
          <b>Principes encadrant la collecte des données</b><br/>
          <br/>
          En publiant cette politique, nous définissons plusieurs objectifs : <br/>
          <br/>
          - la confiance, qui impose que nous détaillions clairement la manière dont nous collectons ces données ; <br/>
          <br/>
          - la transparence, qui demande que nous expliquions pourquoi et comment nous traitons ces données, au-delà même des exigences de la législation ; <br/>
          <br/>
          - la sécurité, qui ne passe pas seulement par le chiffrement, mais aussi et surtout par la réduction de l’étendue de la collecte des données au strict nécessaire, et l’élaboration de processus internes d’accès aux données. <br/>
          <br/>
          <br/>
          <br/>
          <b>Conformément à la législation en vigueur, nous collectons des données :</b> <br/>
          <br/>
          - avec votre consentement, exprimé lors de votre inscription ou de votre première visite, et que vous pouvez modifier à tout moment ; <br/>
          <br/>
          - dans votre intérêt, la fourniture de certains services comme les espaces de commentaires ou les newsletters nécessitant le traitement de données personnelles ; <br/>
          <br/>
          - et dans notre intérêt légitime, le financement de la création d’une information spécialisée de qualité, la préservation de notre indépendance, le maintien de la gratuité de nos contenus, le développement de nos offres d’abonnement à tarif réduit. <br/>
          <br/>
          <br/>
          <br/>
          <b>Collecte des données sur nos sites</b>
          <br/>
          Lorsque vous consultez nos sites, nous enregistrons les données suivantes dans unjournal au format Common Log: <br/>
          <br/>
          - votre adresse IP ; <br/>
          <br/>
          - la date et l’heure de votre visite ; <br/>
          <br/>
          - la référence de la page que vous avez visitée ; <br/>
          <br/>
          - le protocole et la méthode de la requête ; <br/>
          <br/>
          - le code HTTP renvoyé ; <br/>
          <br/>
          - la taille du transfert de données. <br/>
          <br/>
          <br/>
          Nous collectons ces informations techniques afin de détecter d’éventuels problèmes, comme la disparition d’une page ou une tentative d’attaque par déni de service. Nous les utilisons aussi pour mieux comprendre notre référencement, en identifiant les visites réalisées par des robots d’indexation. Ces données sont conservées 12 mois. Si vous le souhaitez, vous pouvez créer un compte pour participer aux commentaires. <br/>
          <br/>
          <br/>
          Pour créer un compte, vous devez nous communiquer les données suivantes : <br/>
          <br/>
          - votre pseudonyme ; <br/>
          <br/>
          - un mot de passe ; <br/>
          <br/>
          - votre adresse de courrier électronique. <br/>
          <br/>
          <br/>
          Votre pseudonyme est (nécessairement) public. Votre adresse de courrier électronique est masquée, mais vous pouvez autoriser les autres membres à vous contacter par le biais d’un formulaire de contact personnel. Votre mot de passe est chiffré et salé de telle manière qu’il nous est impossible de le consulter. <br/>
          <br/>
          Toutes ces données sont conservées tant que vous êtes inscrit, comme la date de création de votre compte, que vous pouvez consulter sur votre profil. Lorsque vous vous connectez à nos sites, nous associons votre adresse IP à votre identifiant de session, afin de prévenir certains risques de sécurité. Lorsque vous vous déconnectez, ou lorsque votre session expire, nous supprimons votre adresse IP. <br/>
          <br/>
          Lorsque vous publiez un commentaire, votre adresse IP est associée au contenu. Nous comptons supprimer cette collecte, ou du moins fortement limiter sa durée dans le temps. <br/>
          <br/>
          <br/>
          <br/>
          <b>Collecte des données dans nos applications</b><br/>
          <br/>
          Lorsque vous utilisez nos applications, nous enregistrons un identifiant unique dans le trousseau local de votre appareil, afin de faciliter une éventuelle réinstallation. <br/>
          <br/>
          Lorsque vous vous inscrivez sur l’application, nous associons votre identifiant d’abonnement et vos préférences à votre compte des sites, afin de faciliter la restauration de votre abonnement d’un appareil à un autre. Les données de certaines fonctions sont enregistrées dans votre compte iCloud à l’aide de CloudKit: <br/>
          <br/>
          - liste des favoris ; <br/>
          <br/>
          - statut de lecture des articles ; <br/>
          <br/>
          - liste des commentaires suivis ; <br/>
          <br/>
          - liste des utilisateurs masqués. <br/>
          <br/>
          <br/>
          <br/>
          <b>Collecte des données pour nos sondages</b><br/>
          <br/>
          Lorsque vous participez à l’un de nos sondages, nous enregistrons votre adresse IP afin d’empêcher les votes multiples. Cette collecte n’est pas croisée avec d’autres, et ne fait l’objet d’aucun traitement supplémentaire. <br/>
          <br/>
          <br/>
          <br/>
          <b>Collecte des données sur nos services</b><br/>
          <br/>
          Comme lorsque vous visitez nos sites, nous enregistrons certaines données dans un journal au format Common Log lorsque vous utilisez nos services, avec les mêmes objectifs. <br/>
          <br/>
          Par ailleurs, nous enregistrons votre adresse IP lors de votre inscription. <br/>
          <br/>
          Votre pseudonyme est public. Votre adresse de courrier électronique est masquée, mais les autres membres peuvent vous contacter par le biais d’un formulaire de contact personnel. <br/>
          Vous pouvez choisir d’afficher votre numéro de téléphone, afin que les autres membres puissent vous contacter directement. <br/>
          Votre adresse postale est utilisée pour trier les postes par localisation, et vérifier la légitimité de votre annonce par divers moyens de vérification, afin de limiter les risques d’arnaque. Votre mot de passe est chiffré et salé de telle manière qu’il nous est impossible de le consulter. <br/>
          Vous pouvez créer un compte sur Plaiz afin de créer une alerte de disponibilité d’un produit ou de vous abonner à la newsletter quotidienne. Pour créer ce compte, vous devez nous communiquer les données suivantes :<br/>
          - votre adresse de courrier électronique ; <br/>
          - un mot de passe. <br/>
          Votre mot de passe est chiffré et salé de telle manière qu’il nous est impossible de le consulter. Les alertes que vous avez programmées et la newsletter seront envoyées à l’adresse de courrier électronique spécifiée. Vous pouvez vous désabonner à tout moment. <br/>
          <br/>
          <br/>
          <br/>
          <b>Cookies</b><br/>
          <br/>
          Les cookies sont de petits fichiers enregistrés dans votre navigateur, qui permettent de conserver des données d’une visite à l’autre, pour diverses raisons. Nous distinguons quatre types de cookies : <br/>
          - les cookies techniques, qui permettent d’assurer la bonne marche de certaines fonctions ; <br/>
          - les cookies de réseaux sociaux, qui facilitent le partage de contenus avec les réseaux sociaux ; <br/>
          - les cookies de mesure d’audience, qui permettent de mesurer l’audience de nos sites, de nos forums et de nos services ; <br/>
          - les cookies publicitaires, qui permettent d’assurer le bon fonctionnement des encarts publicitaires, mais aussi de comptabiliser le nombre d’affichages et de clics des publicités, et de personnaliser les contenus publicitaires. <br/>
          <br/>
          Lorsque vous visitez nos sites ou nos forums, Plaiz enregistre un cookietechnique dans votre navigateur, destiné : <br/>
          - à maintenir la connexion à votre compte d’une visite à l’autre ; <br/>
          - à afficher les résultats du sondage après votre vote ; <br/>
          - à mémoriser votre choix d’enregistrement des cookies tiers. <br/>
          Ce cookie expire après 30 jours. <br/>
          <br/>
          Plusieurs partenaires sont susceptibles d’enregistrer des cookies : <br/>
          - techniques : <br/>
          - Facebook, si vous utilisez votre compte Facebook pour vous inscrire sur Plaiz; <br/>
          - de mesure d’audience : <br/>
          - Analytics, la solution de mesure d’audience de Google ; <br/>
          La durée de vie de ces cookies ne dépasse pas 13 mois. <br/>
          <br/>
          Vous pouvez refuser l’enregistrement de tous les cookies dans les réglages de votre navigateur, auquel cas certaines fonctions de nos sites et services seront inopérantes. Vous pouvez sinon personnaliser l’enregistrement des cookies de nos partenaires (et d’autres cookies) à l’aide des liens suivants : <br/>
          - Analytics ; <br/>
          <br/>
          Attention toutefois : vos préférences en matière d’enregistrement des cookies sont enregistrées… dans un cookie. Si vous supprimez les cookies de votre navigateur, vous perdrez donc ces préférences. <br/>
          <br/>
          <br/>
          <br/>
          <b>Transfert des données</b><br/>
          <br/>
          Certaines de ces données sont susceptibles d’être stockées en dehors de l’Union européenne. Nous vérifions régulièrement les pratiques de nos partenaires, afin de nous assurer qu’ils respectent bien le règlement européen 2016/679, et nous engageons à rompre nos relations avec ceux qui ne le feraient pas. Pour plus d’informations, nous vous invitons à consulter les politiques de nos partenaires : <br/>
          <br/>
          - Google ; <br/>
          <br/>
          Enfin, vos données peuvent être communiquées aux forces de l’ordre ou à la justice, à condition toutefois que nous recevions une demande dûment motivée accompagnée d’un périmètre clairement défini. <br/>
          <br/>
          <br/>
          <br/>
          <b>Sécurité des données</b><br/>
          <br/>
          Nous mettons tout en oeuvre pour assurer la sécurité de vos données. Les communications avec nos sites, nos services et nos applications sont chiffrées. Les communications avec nos partenaires sont chiffrées et effectuées sur un canal sécurisé. L’accès à nos bases de données et nos outils d’administration est strictement contrôlé et restreint à certains membres de notre équipe. <br/>
          <br/>
          Au cas où nous serions victimes d’une faille ou d’une perturbation compromettant la sécurité de vos données personnelles, nous nous engageons à informer la CNIL dans les conditions décrites par l’article 33 du règlement européen 2016/679. Nous vous informerons, ainsi que nos partenaires concernés le cas échéant, dans un délai de 24 heures en suivant les conditions décrites par l’article 34. <br/>
          <br/>
          <br/>
          <br/>
          <b>Portabilité et suppression des données</b><br/>
          <br/>
          En plus du droit d’être informé sur l’utilisation de vos données privées, la législation vous confère un droit à la portabilité des données personnelles et droit à l’effacement. Pour exercer votre droit à la portabilité, merci de contacter notre délégué à la protection des données à l’adresse hello@plaiz.io<br/>
          <br/>
          Pour exercer votre droit à l’effacement, merci de contacter notre délégué à la protection des données à l’adresse hello@plaiz.io<br/>
          <br/>
          Soyez conscient que le droit à l’effacement de vos données personnelles n’est pas un droit à l’effacement de vos commentaires ou discussions. Conformément au cadre légal et à nos CGU, les commentaires et discussions seront anonymisés lors de la suppression de votre compte. Vous conservez bien sûr vos droits de rectification et de suppression des éventuelles données personnelles que contiendraient vos commentaires et discussions. <br/>
          Pour toute autre demande, merci de contacter notre délégué à la protection des données à l’adresse hello@plaiz.io<br/>
          Modification de cette politique<br/>
          <br/>
          Cette politique est un document vivant, susceptible d’être revu à tout moment et sans préavis. <br/>
          <br/>
          <br/>
          <br/>
          <b>En savoir plus</b><br/>
          <br/>
          Vous avez besoin d’explications supplémentaires ? Vous avez une question ? Vous avez une suggestion ? N’hésitez pas à nous contacter notre délégué à la protection des données à l’adresse hello@plaiz.io<br/>
          <br/>
          <br/>

        </p>
    </h4>
    </div>
    </div>
    </div>
    </div>
)

export default politiquededonnees

const styles = {
  backToHomeLink: {
    color: "white",
  },
}