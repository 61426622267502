import React, { Component } from "react"
import { Link } from "gatsby"

/*image*/
import chat from "../assets/chat.svg"


import "./Navigation.css"

export default class Navigation extends Component {
  render() {
    return (
      <div>

          <div className="containerNavigation row justify-content-between align-items-center">
            <Link to={"/"}><img src={chat}/></Link>
            {/*TODO: if we're already on the blog, we don't want to see the blog link anymore*/}
              {/*<p className="visiter mr-3"><Link style={styles.link} to="/blog">Visiter le Blog Plaiz</Link></p>*/}

          </div>

      </div>
    )
  }
}

const styles = {
  link: {
    color: 'white',
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 'bold',
    cursor: 'pointer',
    zIndex: '2',
    letterSpacing: '3px',
  }
}